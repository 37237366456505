import { render, staticRenderFns } from "./PromoModal.vue?vue&type=template&id=29b4a30a&"
import script from "./PromoModal.vue?vue&type=script&lang=js&"
export * from "./PromoModal.vue?vue&type=script&lang=js&"
import style0 from "./PromoModal.vue?vue&type=style&index=0&id=29b4a30a&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports