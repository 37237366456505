<template>
  <div class="m promo-modal">
    <div class="m__header promo-modal__header">
      <h1 class="m__title promo-modal__title">Введите промокод</h1>
      <button class="m__close" type="button" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <form class="m__body promo-modal__body">
      <InputComponent
        v-model.trim="form.promocode.value"
        :errors="form.promocode.errors"
        autofocus
        title="Введите промокод"
        type="promocode"
        class="promo-modal__input"
      />
      <div class="promo-modal__buttons">
        <button
          class="btn btn--white promo-modal__btn promo-modal__btn--white"
          :disabled="loading"
          @click="skip"
        >
          <LoadingIndicator title="Обработка" v-show="loading" />
          <span v-show="!loading">Пропустить</span>
        </button>
        <button class="btn btn--main promo-modal__btn" :disabled="loading" @click="buy">
          <LoadingIndicator title="Обработка" v-show="loading" />
          <span v-show="!loading">Применить</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "PromoModalComponent",
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        promocode: {
          errors: [],
          value: null,
        },
      },
    };
  },
  methods: {
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    buy() {
      this.loading = true;
      this.$store
        .dispatch("BUY", { apollo: this.$apollo, id: this.id, promocode: this.form.promocode.value })
        .then(() => {
          this.loading = false;
        })
        .catch(({ graphQLErrors }) => {
          this.resetErrors();
          this.parseGqlErrors(graphQLErrors);
          this.loading = false;
        });
    },
    skip() {
      this.loading = true;
      this.$store.dispatch("BUY", { apollo: this.$apollo, id: this.id }).then(() => {
        this.loading = false;
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
  },
  components: {
    InputComponent,
    IconComponent,
    LoadingIndicator,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/modal.styl"
.promo-modal {
  max-width 312px
  border-radius 16px
  top 200px

  &__header {
    border-bottom 1px solid var(--gray_4)
    padding 20px 16px
  }

  &__title {
    font-size 1.125em
    font-weight 500
    line-height 25px
    text-transform none
  }

  &__body {
    padding 16px
  }

  &__input {

    .input__title {
      font-size .875em
      line-height 16px
      color var(--gray_5)
    }

    .input__errors {
      margin 0
      padding 0
      list-style-type none
    }
  }

  &__buttons {
    display flex
    align-items center
    justify-content center
    gap 16px
  }

  &__btn {
    max-width 132px
    max-height 48px
    font-size .815em
    line-height 140%

    &--white {
      color var(--dark)
      border 1px solid var(--gray_3)

    }
  }
}
</style>
