<template>
  <section class="home-subjects" ref="subjects" id="subjects">
    <h2 class="home-subjects__title">Субъекты<span class="green-dot">.</span></h2>
    <div class="home-subjects__slides">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in subjects" :key="i">
            <ImgComponent :head_img="item.img" class="home-subjects__slide-item" />
            <div class="home-subjects__slide-infos">
              <span>{{ item.title }} — {{ item.price | formatPrice }} ₽</span>
              <div class="home-subjects__slide-infos__btn">
                <button
                  v-if="!item.available"
                  class="btn"
                  @click="handleClick(item.id)"
                  type="button"
                  :class="{ 'btn--main': !green, 'btn--white': green }"
                  :disabled="loading"
                >
                  <LoadingIndicator title="Обработка" v-show="loading" />
                  <span v-show="!loading">Купить</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-subjects__pagination"></div>
    <img
      src="/static/svg/default/overview-background.svg"
      alt="My Projects"
      class="home-subjects__background"
    />
  </section>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";
// import LoginModal from "components/modals/components/LoginModal.vue";
import ImgComponent from "components/ImgComponent.vue";
import LoginPhoneModal from "components/modals/components/LoginPhoneModal.vue";
import PromoModal from "components/modals/components/PromoModal.vue";

export default {
  name: "HomeSubjectsComponent",
  components: { ImgComponent, LoadingIndicator },
  data() {
    return {
      loading: false,
      green: false,
      swiper: null,
    };
  },
  methods: {
    scrollTo(refName) {
      window.scrollTo({ top: this.$refs[refName].offsetTop, behavior: "smooth" });
    },
    handleClick(id) {
      if (!this.loading) {
        this.loading = true;
        if (this.$store.state.user) {
          this.$store.state._modals.push({
            component: PromoModal,
            options: {
              id: id,
            },
          });
          this.loading = false;
        } else {
          this.$store.state._modals.push({
            component: LoginPhoneModal,
          });
          this.loading = false;
        }
      }
    },
  },
  computed: {
    subjects() {
      return this.$store.state.subjects;
    },
  },
  mounted() {
    if (this.$route.hash === "#subjects") {
      this.scrollTo("subjects");
    }
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: true,
        pagination: {
          el: ".home-subjects__pagination",
          dynamicBullets: true,
        },
        breakpoints: {
          // 315: {
          //   slidesPerView: 1,
          //   spaceBetween: 0,
          // },
          350: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          380: {
            slidesPerView: 1.2,
            spaceBetween: 0,
          },
          420: {
            slidesPerView: 1.4,
            spaceBetween: 16,
          },
          450: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          490: {
            slidesPerView: 1.6,
            spaceBetween: 16,
          },
          560: {
            slidesPerView: 1.8,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1240: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1570: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
      });
      if (this.windowSize > 1570) {
        if (this.mockups.length >= 5) {
          this.swiper.slideTo(3);
        }
      } else if (this.windowSize > 1440) {
        if (this.mockups.length >= 4) {
          this.swiper.slideTo(2);
        }
      } else {
        if (this.mockups.length >= 3) {
          this.swiper.slideTo(2);
        }
      }
    });
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.home-subjects {
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  position relative
  margin-bottom 100px
  +below(768px) {
    gap 20px
    margin-bottom 40px
  }

  &__background {
    absolute left bottom
    transform translateY(100%)
  }

  &__title {
    font-weight: 800;
    font-size: 2.125em;
    text-align: center;
    color: var(--main);
    margin 0
    +below(768px) {
      font-size 1.8em
    }
  }

  .stats {
    padding 0 100px
    max-width 1000px
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px
    margin-top 50px
    +below(1200px) {
      padding 40px
      grid-template-columns repeat(2, 1fr)
    }
    +below(768px) {
      padding 20px
      grid-template-columns 1fr
    }
    +below(560px) {
      padding 16px
    }
  }

  &__slides {
    position relative
    width 100%

    .swiper-slide {
      width auto
      display flex
      flex-direction column
      justify-content center
      align-items center
      height 100%
      transition var(--transition)
      padding 30px 0
      +above(1641px) {
        padding 50px 0
        &.swiper-slide-active {
          transform scale(1.1)
          padding 60px 0
        }
      }

      img {
        max-width 280px
        max-height 575px
        transition var(--transition)
        +above(1641px) {
          filter: drop-shadow(0 0 20px #0000003b);
        }
      }

      .home-subjects__slide-item {
        border-radius 10px
        max-width 308px
        height 500px
        max-height 500px
        margin-bottom 40px
        +below(310px) {
          padding 0 8px
        }
      }
      .home-subjects__slide-infos {
        width 100vh
        display none
        flex-direction column
        align-items center
        gap 15px

        & .btn {
          padding 0 55px
        }
      }

      &-active {
        .home-subjects__slide-infos {
          display flex
        }
      }
    }
  }

  &__pagination {
    margin 0 auto
    left 0 !important
    transform none !important

    .swiper-pagination-bullet {
      width 20px
      height 20px
      background var(--main)
      opacity 0.3
    }

    .swiper-pagination-bullet-active {
      background var(--green)
      opacity 1
    }

  }
}
</style>
